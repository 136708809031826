// this is kept just for color refence
// this is kept just for color refence
$colors: (
	neutral:(0:#ffffff,
		5:#F4F4F6,
		10:#E3E4E7,
		20:#C8CAD0,
		30:#B8BAC1,
		40:#A2A5AE,
		50:#848894,
		60:#72757E,
		70:#595D69,
		80:#3B3E4A,
		90:#2B2D31,
		100:#17181C),
	primary:(0:#E8EDFC,
		10:#A6BAF7,
		20:#7795F3,
		30:#5B7FF1,
		40:#436DEF,
		50:#2250E3,
		55:#0041A3,
		60:#1D48D3,
		70:#183EBA,
		80:#122F8C,
		85:#002E75,
		90:#0C205F,
		100:#061132),
	secondary:(0:#ECF9EC,
		10:#D9F3D9,
		20:#B4E7B3,
		30:#8EDC8C,
		40:#69D066,
		50:#43C440,
		60:#3CB03A,
		70:#369D33,
		80:#2F892D,
		90:#226220,
		100:#143B13),
	positive:(0:#E5F9ED,
		40:#96E7B5,
		50:#24D67D,
		60:#00BC59,
		90:#00662A,
	),
	notice:(0:#FFF4C1,
		40:#FFED99,
		50:#FDDB33,
		60:#F8D219,
		90:#635304,
	),
	negative:(0:#FFEEF0,
		40:#EC807E,
		50:#FF5945,
		60:#DD463D,
		70:#FF1A1A,
		90:#85150C,
	),
	informative:(0:#E3F2FF,
		10:#E5F0FF,
		40:#91CAFF,
		50:#45A5FF,
		60:#2A87EF,
		90:#2649C9),
	shimmer:(light:#f1f1f1,
		dark:#ABABAB),
	gradient:(0:#16181d99),
	gradient1:(0:#D4C482),
	pure-black:(0:#000000,
		30:hsla(0, 0%, 0%, 0.302),
	),
	focus: (0: #0066ff),
	dark-focus: (0: #2E81FF),
	specific-selected: (0: #002E75),
	medium-black: (0: #271E31),
	gold: (0: #F4CA72),
	silver: (0: #E5E5E5),
	bronze: (0: #D5B58C),
	spotify: (0: #44826c)
);
$hex-shades: (
	"neutral":(0:(0, 0%, 100%),
		5:(0, 0%, 96%),
		10:(225, 8%, 90%),
		20:(225, 8%, 80%),
		30:(227, 7%, 74%),
		40:(225, 7%, 66%),
		50:(228, 7%, 55%),
		60:(225, 5%, 47%),
		70:(224, 8%, 38%),
		80:(228, 11%, 26%),
		90:(220, 7%, 18%),
		100:(228, 10%, 10%)),
	"primary":(0:(225, 77%, 95%),
		10:(225, 84%, 81%),
		20:(225, 84%, 71%),
		30:(226, 85%, 65%),
		40:(225, 84%, 60%),
		50:(226, 78%, 51%),
		55:(216, 100%, 32%),
		60:(226, 76%, 47%),
		70:(226, 77%, 41%),
		80:(226, 77%, 31%),
		85:(216, 100%, 23%),
		90:(226, 78%, 21%),
		100:(225, 79%, 11%)),
	"secondary":(0:(120, 52%, 95%),
		10:(120, 52%, 90%),
		20:(119, 52%, 80%),
		30:(119, 53%, 71%),
		40:(118, 53%, 61%),
		50:(119, 53%, 51%),
		60:(119, 50%, 46%),
		70:(118, 51%, 41%),
		80:(119, 51%, 36%),
		90:(118, 51%, 25%),
		100:(119, 51%, 15%)),
	"positive":(0:(144, 62%, 94%),
		40:(143, 63%, 75%),
		50:(150, 71%, 49%),
		60:(148, 100%, 37%),
		90:(145, 100%, 20%),
	),
	"notice":(0:(49, 100%, 88%),
		40:(49, 100%, 80%),
		50:(50, 98%, 60%),
		60:(50, 94%, 54%),
		90:(50, 92%, 20%),
	),
	"negative":(0:(353, 100%, 97%),
		40:(1, 74%, 71%),
		50:(6, 100%, 64%),
		60:(3, 70%, 55%),
		70:(0, 100%, 55%),
		90:(4, 83%, 28%),
	),
	"informative":(0:(208, 100%, 95%),
		10:(215, 100%, 95%),
		40:(209, 100%, 78%),
		50:(209, 100%, 64%),
		60:(212, 86%, 55%),
		90:(227, 68%, 47%)),
	"shimmer":(light:(0, 0%, 95%),
		dark:(0, 0%, 67%)),
	"gradient":(0:(223, 14%, 10%),
	),
	"gradient1":(0:(48, 49%, 67%)),
	"pure-black":(0:(0, 0%, 0%),
		30:(0, 0%, 30%)),
	"focus": (0: (216, 100%, 50%)),
	"dark-focus": (0: (216, 100%, 59%)),
	"specific-selected": (0: (216, 100%, 23%)),
	"medium-black": (0: (268, 24%, 15%)),
	"gold":(0:(41, 86%, 70%)),
	"silver":(0:(0, 0%, 90%)),
	"bronze":(0:(34, 46%, 69%)),
	"spotify":(0:(159, 31%, 39%))
);
// shades ends
$aspect-ratio: (
	4 3,
	3 4,
	16 9,
	1 1,
	2 3,
	9 16,
	21 9,
	18 9,
	18 6,
	6 4,
	7 8,
	//Gallery Desktop
	9 13,
	//Multimedia Mobile
	5 11,
	//Sports Video
	9 5
);
$flex-width: (
	5,
	10,
	20,
	25,
	30,
	33,
	40,
	50,
	60,
	70,
	80,
	100
);
$font-detail: (
	Manrope:(pr:(Manrope-Regular: 400),
		pm:(Manrope-Medium: 600),
		pb:(Manrope-Bold: 700)),
	Ubuntu:(sr:(Ubuntu-Regular: 400),
		sm:(Ubuntu-Medium: 600),
		sb:(Ubuntu-Bold: 700))
);
$titles: (
	10:(56, 1.3),
	20:(40, 1.2),
	30:(32, 1.3),
	40:(24, 1.4),
	50:(20, 1.4),
	60:(14, 1.6)
);
$directions: (
	t: -top,
	r: -right,
	b: -bottom,
	l: -left,
	x: -inline,
	y: -block,
);
$flex-spacing: (
	-sa: space-around,
	-sb: space-between,
	-se: space-evenly,
	-fe: flex-end,
	-fs: flex-start,
	-c:center,
	-s:stretch,
	-u:unset,
	-n:null
);
$media-query: (
	col-sm: 360px,
	col-md: 600px,
	col-tablet: 768px,
	col-lg: 1024px,
	col-desktop: 1250px,
	col-xl: 1440px,
	col-xxl: 1660px
);
$zindex: (
	multimedia-item-icon: 1,
	header:6,
	header-nav:6,
	search-result-modal: 4,
	search-button: 6,
	backdrop-modal: 3,
	list-anchor-overlay:-1,
	status:1,
	banner-event-overlay:-1,
	overlay-icon:1,
	dropdown-wrap:1,
	banner-position:1,
	showcase-card: 1,
	event-sports:-1,
	content-bg:-1,
	pattern: -1,
	livestrip:1,
	filter:7,
	input:1,
	filter-dropdown: 2,
	secondary-nav:5,
	waf-dropdown: 1,
	content:2,
	hero-content: 2,
	athlete-search-list: 1,
	modal:7,
	loader: 11,
	eventborder: 1,
	searchlist: 2,
	multisportlegend-bg: -1,
	event-participate-bg: -1,
	commitment-bg: -1,
	sportsclass-bg: -1,
	arena-banner: 1,
	default: 1,
	media-vector:1,
	calendar:1,
	banner-bg: -2,
	headernegative:-1,
	youtube-iframe:2,
);
$icons: (
	"academic-cap":'\e800',
	"academic-cap-fill":'\e801',
	"arrow-down":'\e802',
	"arrow-left":'\e803',
	"arrow-long-down":'\e804',
	"arrow-long-left":'\e805',
	"arrow-long-right":'\e806',
	"arrow-long-up":'\e807',
	"arrow-right":'\e808',
	"arrow-small-down":'\e809',
	"arrow-small-left":'\e80a',
	"arrow-small-right":'\e80b',
	"arrow-small-up":'\e80c',
	"arrow-trending-down":'\e80d',
	"arrow-trending-up":'\e80e',
	"arrow-up":'\e80f',
	"calendar":'\e810',
	"calendar-days":'\e811',
	"calendar-days-fill":'\e812',
	"calendar-fill":'\e813',
	"chart-bar-square":'\e815',
	"chevron-double-down":'\e816',
	"chevron-double-left":'\e817',
	"chevron-double-right":'\e818',
	"chevron-double-up":'\e819',
	"chevron-down":'\e81a',
	"chevron-left":'\e81b',
	"chevron-right":'\e81c',
	"chevron-up":'\e81d',
	"clock":'\e81e',
	"close":'\e820',
	"eye":'\e823',
	"eye-slash":'\e825',
	"eye-slash-fill":'\e826',
	"face-smile":'\e828',
	"filter":'\e82a',
	"filter-fill":'\e82b',
	"filter-vertical":'\e82c',
	"filter-vertical-fill":'\e82d',
	"folder-open":'\e82e',
	"folder-open-fill":'\e82f',
	"list-bullet":'\e832',
	"live":'\e833',
	"map-pin":'\e834',
	"megaphone":'\e836',
	"megaphone-fill":'\e837',
	"menu-bar":'\e838',
	"photo":'\e839',
	"newspaper-fill":'\e83a',
	"photo-fill":'\e83b',
	"play-fill":'\e83c',
	"play":'\e83d',
	"search":'\e83e',
	"trophy-fill":'\e840',
	"trophy":'\e841',
	"newspaper":'\e843',
	"youtube":'\e8de',
	"facebook":'\e827',
	"instagram":'\e831',
	"linkedin":'\e830',
	"tiktok":'\e83f',
	"twitter":'\e842',
	"bluesky": '\e8dd',
	"flag": '\e890',
	"printer": '\e964',
	'check': '\e898',
	'arrow-down-tray': '\e84d',
	'globe':'\e86f',
	'heart':'\e86e',
	'comment':'\e86d',
	"info": "\e870",
	"document-chartbar": "\e84e",
	"document": "\e84c",
	"document-blank": "\e857",
	"excel": "\e932",
	"run": "\e85b",
	"bike": "\e85a",
	"swim": "\e85c",
	"sports":"\e88b",
	"magnifying-glass-plus":"\e88a",
	"arrow-top-right-on-square":"\e814",
	"arrows-up-down": "\e867",
	"website": '\e86f',
	"blog": '\e844',
	"threads": '\e868',
	"globe1": '\e86a',
	"plus": '\e86c',
	"circle-plus": '\e86b',
	"mail": '\e814',
	"chevron-up-down": "\e822"
);
$social: (
	"youtube":'\e8de',
	"facebook":'\e827',
	"instagram":'\e831',
	"linkedin":'\e830',
	"tiktok":'\e83f',
	"twitter":'\e842',
	"website": '\e86f',
	"blog": '\e844',
	"threads": '\e868',
	"bluesky": '\e8dd'
);
$federation-bg: (
	america-federation: america,
	europe-federation: europe,
	africa-federation: africa,
	asia-federation: asia,
	oceania-federation: ocenia
);
// body text starts
$body-texts: (
	s: (12, 400),
	sb: (12, 700),
	m: (14, 400),
	mb: (14, 700),
	l: (16, 400),
	lb: (16, 700),
);
// body text ends
// border radius starts
$border-radius: (
	xs:4,
	s:8,
	r:12,
	m:16,
	l:24,
	xl:32
); // border radius ends
//map for world ranking images
$world-ranking: (
	world-championships:world-championship,
	europe:europe,
	oceania:europe,
	africa:africa,
	arena:arena,
	asia:asia,
	paris:paris,
	america:america
);
$medals: (
	"gold":"gold",
	"silver":"silver",
	"bronze":"bronze"
);
$event-listing-bg-img: (
	"dua":'dua',
	"aqua":'aqua',
	"cross":'cross',
	"long-dis":'long-dis',
	"winter":'winter-triathlon',
	"elite":'elite',
	"para":'para',
	"arena":'arena',
	"development":'development',
	"upcoming":'upcoming',
);
@use "./map" as *;
// scss variables
$base-space: 0.4rem;
$max-space-count: 25; // maximum count variable generation
$default-line-height: 1.6;
$body-font-size: 1.4rem;
$body-font-weight: 400;
// media query variables
$sm-min-width: 0px;
$sm-max-width: 599px;
$sm-mobile-max-width: 360px;
$md-min-width: 768px;
$md-max-width: 1023px;
$lg-min-width: 1024px;
$lg-max-width: 1439px;
$xl-min-width: 1440px;
$xxl-min-width: 1660px;
// font variables
$font-primary: "Manrope",
    sans-serif;
$font-secondary: "Ubuntu",
    sans-serif;
$font-icon: "waf-font-icon";
//  version variables
$image-version: "5.4";
$font-version: "67762348";
// css variables
:root {
    // non color variables
    --base-font-size: 62.5%;
    --vh: 1vh;
    --vw: 1vw;
    --window-inner-height: calc(var(--vh, 1vh) * 100);
    --window-inner-width: calc(var(--vw, 1vw) * 100);
    --content-width: var(--window-inner-width);
    --container-white-space: 1.6rem;
    --xl-container-white-space: 0rem;
    --container-max-width: calc(var(--window-inner-width) - 2 * var(--container-white-space));
    --lg-container-max-width: calc(var(--window-inner-width) - 2 * var(--container-white-space));
    --header-height: 5.6rem;
    --logo-width: 10rem;
    --header-redirect-height: 4.8rem;
    --swiper-button-width: 4.8rem;
    --swiper-button-height: 4.8rem;
    --livestrip-height: 12.4rem;
    --secondary-header-height: 10.4rem;
    // --secondary-logo-width: 13.5rem;
    --secondary-logo-width: 14.4rem;
    // space variable
    @for $i from 1 through $max-space-count {
        --space-#{$i}: #{$base-space * $i};
        --space-#{$i}-neg: #{$base-space * $i * -1};
    }
    --space-0:0;
    // use
    // --space-1 = 4px
    // --space-2 = 8px.... till --space-12 = 48px
    // this is according to base space variable
    --full-radius: 1rem;
    --half-radius: calc(var(--full-radius) / 2);
    // **********this is just example for naming convention so please remove it and use it***********
    // zindex variable ********** assending order *******************
    @each $name,
    $index in $zindex {
        --z-#{$name}:#{$index};
    }
    @each $radius,
    $value in $border-radius {
        --border-radius-#{$radius}:#{($value)*0.1rem};
    }
}
@media (min-width: $md-min-width) {
    :root {
        --container-white-space: 3.2rem;
        --lg-container-max-width: calc(var(--window-inner-width) - var(--container-white-space));
        --secondary-header-height: 8rem;
        --livestrip-height: 8.8rem;
    }
}
@media (min-width: $lg-min-width) {
    :root {
        --container-white-space: 6.4rem;
        --logo-width: 15rem;
        --header-height: 8rem;
    }
}
@media (min-width: $xl-min-width) {
    //
    :root {
        --container-white-space: 8rem;
        --container-max-width: min(calc(var(--window-inner-width) - 2 * var(--container-white-space)), 166rem);
        --lg-container-max-width: min(calc(var(--window-inner-width) - var(--container-white-space)), 166rem);
        --xl-container-white-space: calc((var(--window-inner-width) - var(--container-max-width)) / 2);
    }
}
@media (min-width: $xxl-min-width) {
    :root {
        --lg-container-max-width: min(calc(var(--window-inner-width) - var(--container-white-space)), 174rem);
    }
}
@use '../config/' as *;
$buttons: (
	'print': '\e964',
	'excel': '\e932',
	'pdf': '\e857'
);
$sortIcons: (
	'ascending': 'arrow-long-up',
	'descending': 'arrow-long-down'
);
.waf-standings {
	--buttons-wrap-width: 7.6rem;
	--pos-wrap-width: 8.2rem;
	--pos-width: 3.6rem;
	--diff-width: 4rem;
	--events-width: 4.6rem;
	--total-points-width: 5.6rem;
	--action-width: 2rem;
	--player-image-width: 3.2rem;
	--flag-width: 1.7rem;
	--row-gap: .6rem;
	@extend %page-head-blue-bg;
	@extend %common-input-swiper-btn;
	@extend %tabs-underline;
	&.waf-component {
		padding-block: 0 var(--space-20);
		@extend %mb-0;
	}
	.title {
		@extend %mb-0;
	}
	.waf {
		&-head {
			height: auto;
			min-height: 35rem;
			@extend %flex-n-c;
		}
		&-body {
			margin-top: -5rem;
			@extend %py-4;
		}
	}
	.head-wrap {
		margin-block: calc(var(--space-10) + var(--header-height)) var(--space-25);
		@extend %flex-column;
		@extend %gap-3;
	}
	.tabs {
		@include border(1, neutral-20, 10, bottom);
		@extend %px-4;
		@extend %mb-4;
	}
	.tab-container {
		@extend %px-2;
	}
	.table-content-wrap {
		@extend %mt-14;
		> *:not(:last-child) {
			@extend %mb-2;
		}
		.text,
		li {
			font-size: 1.2rem;
			line-height: 1.33;
			@extend %pure-black-0;
		}
		ul {
			list-style-position: inside;
			list-style-type: disc;
		}
	}
	.head-tag,
	.desc {
		font-weight: bold;
		@extend %neutral-5;
	}
	.site-search-form {
		flex: 1;
		.search-input {
			padding-block: 1rem;
			border-radius: unset;
			background-color: unset;
			font-size: 1.4rem;
		}
		.btn-close {
			@extend %d-none;
		}
	}
	.action-wrap {
		@extend %flex-n-c;
		@extend %gap-2;
	}
	.btn {
		@extend %flex;
		&::before {
			font: 2rem/1 $font-icon;
		}
		.text {
			@extend %d-none;
		}
	}
	@each $key, $content in $buttons {
		.btn-#{$key}::before {
			content: $content;
		}
	}
	.pagination {
		@extend %mt-7;
		&-wrap {
			@extend %mt-8;
			@extend %text-center;
		}
		button {
			font-size: 1.6rem;
			@extend %neutral-100;
		}
		.nav-arrow {
			width: 3.2rem;
		}
		.active {
			@extend %neutral-0;
		}
	}
	.nav-arrow {
		&::before,
		&::after {
			font-size: 1.6rem;
		}
		span {
			@extend %d-none;
		}
	}
	// table styles
	.table {
		border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);
		overflow-wrap: anywhere;
		&-filter {
			column-gap: var(--space-8);
			@extend %flex-n-c;
			@extend %flex-wrap;
		}
		&-responsive {
			overflow-x: unset;
		}
		&-action-wrap {
			min-height: 5.6rem;
			border: .1rem solid var(--neutral-20);
			border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
			flex-basis: 100%;
			@extend %p-2-4;
			@extend %mt-8;
			@extend %flex-sb-c;
			@extend %bg-neutral-5;
			@extend %gap-4;
		}
		&-title {
			font-size: 1.4rem;
			@extend %mb-0;
		}
		&-row-wrap {
			border-bottom: .1rem solid var(--neutral-20);
			border-inline: .1rem solid var(--neutral-20);
			&:last-child {
				border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);
			}
			&.active {
				border-radius: unset;
				.waf-accordion-body {
					@extend %d-block;
				}
				.table-row {
					row-gap: 0;
					@extend %flex-wrap;
				}
				.btn-accordion::before {
					transform: rotate(180deg);
					@extend %pure-black-0;
				}
			}
		}
		&-head {
			@extend %d-none;
		}
		&-row {
			gap: var(--row-gap);
			background-color: unset;
			@extend %flex-n-c;
		}
		&-data {
			height: 5.5rem;
			justify-content: unset;
			@extend %text-left;
			&.position {
				flex-basis: var(--pos-wrap-width);
			}
			&.name {
				flex: 1;
			}
			&.total-events {
				flex-basis: var(--events-width);
			}
			&.total-points {
				flex-basis: var(--total-points-width);
			}
			&.action {
				max-width: var(--action-width);
				flex-basis: var(--action-width);
			}
		}
		&-body .table-row {
			@extend %relative;
		}
	}
	.men .name .image {
		border: .2rem solid var(--focus-0);
	}
	.women .name .image {
		border: .2rem solid var(--secondary-70);
	}
	.position {
		padding-left: var(--row-gap);
		.text {
			@extend %text-center;
		}
	}
	.pos {
		width: var(--pos-width);
		font-weight: 700;
	}
	.diff {
		width: var(--diff-width);
		@extend %flex-c-c;
		&.text {
			font-size: 1.2rem;
		}
	}
	.ascent,
	.descent {
		.diff::before {
			font: 1.2rem/1 $font-icon;
		}
	}
	.ascent .diff {
		@extend %positive-90;
		&::before {
			content: "\E80C";
			@extend %positive-50;
		}
	}
	.descent .diff {
		@extend %negative-90;
		&::before {
			content: "\E809";
			@extend %negative-50;
		}
	}
	.yob .text {
		font-size: 1.2rem;
	}
	.total-points .text,
	.yob .text {
		font-weight: 600;
	}
	.name {
		.table-data-wrap {
			@extend %flex-n-c;
			@extend %gap-2;
		}
		.image {
			width: var(--player-image-width);
			min-width: var(--player-image-width);
			height: var(--player-image-width);
			aspect-ratio: 1;
			object-fit: cover;
			@extend %circle-radius;
		}
	}
	.lname,
	.fname,
	.table-body .last-name .text {
		line-height: 1.3;
		@include truncate-text(1);
		@extend %focus-0;
	}
	.lname,
	.table-body .last-name .text {
		font-weight: 600;
	}
	.action {
		padding-right: var(--row-gap);
	}
	.nation {
		width: var(--flag-width);
		height: var(--flag-width);
		@include position(2.7rem, null, null, calc(var(--row-gap) + var(--pos-wrap-width) + 1.6rem));
		.image {
			width: var(--flag-width);
			height: var(--flag-width);
			object-fit: cover;
			@extend %circle-radius;
		}
	}
	.btn-accordion {
		@extend %flex;
		&::before {
			@include icon(chevron-down, 14);
			@extend %focus-0;
		}
		.btn-text {
			@extend %font-0;
		}
	}
	.last-name,
	.nation .text {
		@extend %d-none;
	}
	// Inner table styles
	.waf-accordion-body {
		@extend %d-none;
		@extend %bg-neutral-5;
	}
	.inner-table {
		&-head {
			@extend %d-none;
		}
		&-row {
			@extend %px-2;
			@extend %flex-sb-c;
			@extend %flex-wrap;
			&:not(:last-child) {
				border-bottom: .1rem solid var(--neutral-20);
			}
		}
		&-data {
			height: 4.5rem;
			flex-shrink: 0;
			.text {
				font-size: 1.2rem;
			}
		}
	}
	.event-name {
		height: unset;
		min-height: 5.2rem;
		order: -1;
		@extend %w-100;
		@extend %py-2;
		.text-link {
			line-height: 1.33;
			@extend %focus-0;
		}
		&.marked .text::after {
			content: " (*)";
		}
	}
	.counted {
		.text {
			font-weight: 700;
		}
		.inner-points .text {
			@extend %flex-n-c;
			@extend %gap-1;
			&::after {
				content: "";
				width: .5rem;
				aspect-ratio: 1;
				@extend %circle-radius;
				@extend %bg-focus-0;
			}
		}
	}
	.head-tab {
		a {
			width: fit-content;
			padding-inline: var(--space-4);
			border: .1rem solid var(--neutral-5);
			color: var(--neutral-5);
			gap: var(--space-2);
			@extend %btn-outline-text-icon;
			&:hover {
				border: .1rem solid var(--neutral-0);
				color: var(--neutral-0);
			}
			&::after {
				font-weight: 400;
				@include icon(arrow-down, 22);
			}
		}
	}
	.waf-select-box {
		cursor: pointer;
		&.active {
			&::after,
			::after {
				transform: rotate(180deg);
			}
			.select-box-wrap {
				grid-template-rows: 1fr;
			}
			.select-list {
				overflow-y: auto;
			}
		}
	}
	.dropdown-close,
	.sub-title {
		@extend %d-none;
	}
	.list-item {
		height: 4.8rem;
		border-bottom: .1rem solid var(--neutral-20);
		@extend %w-100;
		@extend %flex-c-c;
		@extend %neutral-70;
		@extend %text-m;
		@extend %gap-1;
		&:hover {
			@extend %bg-neutral-5;
		}
	}
	.select {
		&-box-wrap {
			max-height: 19.2rem;
			display: grid;
			box-shadow: 0 .2rem 1.5rem 0 var(--neutral-20);
			grid-template-rows: 0fr;
			overflow-y: auto;
			transition: all .3s ease-in;
			@include position(calc(100% + var(--space-2)), null, null, 0);
			@extend %w-100;
			@extend %zindex-waf-dropdown;
			@extend %bg-neutral-0;
		}
		&-list {
			overflow: hidden;
			@extend %d-block;
		}
	}
	.event-count {
		.waf-select-box {
			height: 3.2rem;
			padding-inline: var(--space-3) var(--space-1);
			border: .1rem solid var(--neutral-5);
			@extend %flex-fe-c;
			@extend %border-radius-xs;
			@extend %relative;
		}
		.selected-label {
			@extend %flex-n-c;
			@extend %gap-1;
			&::after {
				transition: transform .3s ease-in;
				@include icon(chevron-down, 18);
				@extend %text-l;
			}
		}
	}
	.dropdown-wrap {
		flex-basis: 100%;
		@extend %mb-4;
		.waf-select-box {
			height: 4rem;
			border-bottom: .1rem solid var(--neutral-20);
			@extend %px-4;
			@extend %flex-sb-c;
			@extend %relative;
			@extend %gap-4;
			&::after {
				@include icon(chevron-down, 18);
			}
		}
		.list-item {
			padding: 0 var(--space-4);
			justify-content: flex-start;
		}
	}
}
.case-2,
.case-4 {
	.table-row {
		justify-content: unset;
	}
	.events-1,
	.events-2 {
		&.max .text {
			@extend %negative-60;
		}
	}
	.events-1 {
		@extend %pl-2;
	}
	.points-2 {
		@extend %pr-2;
	}
	.points-1,
	.points-2 {
		.text {
			font-weight: 600;
		}
	}
}
.case-4 {
	.lname {
		display: none;
	}
	.fname {
		font-weight: 600;
	}
}
@media screen and (max-width: $sm-mobile-max-width) {
	.waf-standings {
		--pos-wrap-width: 7rem;
		--pos-width: 3rem;
		--diff-width: 3.5rem;
		--total-points-width: 5.2rem;
		.table .text {
			font-size: 1.2rem;
		}
		.diff.text {
			font-size: 1rem;
		}
	}
}
@media screen and (max-width: 767px) {
	.waf-standings {
		.team-name,
		.total-points {
			@include add-label();
		}
		.date,
		.inner-pos,
		.time,
		.inner-points,
		.period {
			@include add-label(1rem, column, true);
			color: var(--neutral-30);
		}
		.total-events,
		.events-1,
		.events-2,
		.points-1,
		.points-2 {
			@include add-label(.8rem);
		}
		.yob {
			@include add-label(.8rem, row);
		}
		.subclass {
			@include add-label(1rem, row);
			justify-content: flex-end;
		}
		.date,
		.inner-pos,
		.time,
		.inner-points,
		.period,
		.events-1,
		.points-1,
		.events-2,
		.points-2,
		.subclass {
			text-align: right;
		}
		.yob,
		.events-1,
		.points-1,
		.events-2,
		.points-2,
		.subclass {
			display: none;
			order: 2;
		}
		.total-events .text {
			font-weight: 600;
		}
		.yob {
			justify-content: space-between;
		}
		.yob,
		.subclass {
			width: calc(100% - var(--space-4));
			height: 2.6rem;
			margin-inline: var(--space-2);
			align-items: center;
		}
		.events-1,
		.points-1,
		.events-2,
		.points-2 {
			width: max-content;
		}
		.table-row-wrap.active {
			.table-row {
				justify-content: space-between;
				&::before,
				&::after {
					content: "";
					width: 100%;
					height: .1rem;
					background-color: var(--neutral-20);
					@include position(null, null, null, 0);
				}
				&::before {
					top: 5.4rem;
				}
				&::after {
					bottom: 0;
				}
			}
			.yob,
			.events-1,
			.points-1,
			.events-2,
			.points-2,
			.subclass {
				display: flex;
			}
		}
		.event-name {
			@include flex-config(flex, null, null, center);
		}
		.total-events,
		.total-points {
			height: 3rem;
			justify-content: space-between;
			&::before {
				line-height: 1.2rem;
			}
		}
	}
	.case-2,
	.case-3 {
		.yob {
			@include border(1, neutral-20, 10, bottom);
		}
	}
	.case-4 .table-data.name {
		flex: 0 0 calc(100% - 3 * var(--row-gap) - var(--pos-wrap-width) - var(--total-points-width) - var(--action-width));
	}
}
@media screen and (min-width: $md-min-width), print {
	.waf-standings {
		--action-width: 1.4rem;
		--pos-wrap-width: 7.2rem;
		--pos-width: 3rem;
		--diff-width: 4rem;
		--yob-width: 10%;
		--events-width: 10.5%;
		--total-points-width: 12%;
		--nation-width: 11%;
		--inner-table-data-width: 10%;
		--inner-date-width: 15%;
		--inner-time-width: 13.4%;
		--inner-points-width: 11%;
		&.waf-component {
			padding-bottom: 9.6rem;
		}
		.waf {
			&-body {
				padding-block: var(--space-6);
				border-radius: var(--border-radius-l);
				margin-top: -9rem;
				@include border(1, neutral-20, 10, all);
			}
			&-head {
				height: 60rem;
				.desc {
					font-size: 1.6rem;
				}
			}
		}
		.head-wrap {
			margin-block: unset;
			gap: var(--space-8);
		}
		.tab-container {
			padding-inline: var(--space-4);
		}
		.tabs {
			margin-bottom: var(--space-6);
		}
		.waf-select-box .selected-label {
			font-size: 1.6rem;
		}
		.dropdown-wrap {
			margin-bottom: 0;
			flex: 1;
		}
		.site-search-form .search-input {
			font-size: 1.6rem;
		}
		.dropdown-wrap .waf-select-box,
		.search-input {
			height: 7.2rem;
		}
		.pagination {
			&:hover {
				color: var(--neutral-0);
			}
			.nav-arrow {
				width: auto;
			}
		}
		.nav-arrow span {
			display: inline;
		}
		.text {
			font-size: 1.2rem;
		}
		.table {
			&-filter {
				margin-bottom: var(--space-10);
				column-gap: var(--space-5);
			}
			&-action-wrap {
				min-height: unset;
				padding: 0;
				border: unset;
				margin-top: 0;
				background-color: unset;
				flex-basis: unset;
			}
			&-title,
			&-header {
				display: none;
			}
			&-head {
				border: .1rem solid var(--neutral-20);
				border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
				display: block;
				background-color: var(--neutral-5);
				.action .text {
					font-size: 0;
				}
				.position,
				.name,
				.last-name,
				.yob,
				.total-events,
				.events-1,
				.events-2,
				.points-1,
				.points-2,
				.total-points,
				.subclass,
				.nation {
					gap: var(--space-1);
					justify-content: space-between;
					&:hover {
						background-color: var(--neutral-20);
						cursor: pointer;
					}
					&::after {
						@include icon(chevron-up-down, 14);
					}
				}
				@each $icon-name, $icon-id in $sortIcons {
					.#{$icon-name}::after {
						font-size: 1.2rem;
						content: map-get($map: $icons, $key: $icon-id);
					}
				}
			}
			&-body {
				.total-points .text {
					font-weight: 700;
				}
				.nation {
					justify-content: center;
				}
			}
			&-row {
				padding-inline: var(--row-gap);
				flex-wrap: unset;
			}
			&-data {
				height: 6rem;
				flex-basis: 8%;
				justify-content: flex-start;
				&.last-name {
					flex: 1;
				}
				&.nation {
					flex-basis: var(--nation-width);
				}
				&.action {
					justify-content: center;
				}
				&.yob {
					flex-basis: var(--yob-width);
				}
			}
		}
		.last-name {
			display: flex;
		}
		.position {
			padding-left: 0;
			gap: .2rem;
			.text {
				text-align: left;
			}
		}
		.diff {
			justify-content: flex-start;
		}
		.action {
			padding-right: 0;
			order: -1;
		}
		.nation {
			width: unset;
			position: unset;
			.table-data-wrap {
				gap: var(--space-2);
				@include flex-config(flex, null, null, center);
			}
			.text {
				display: block;
			}
		}
		.lname {
			display: none;
		}
		.total-events.max .text {
			color: var(--negative-60);
		}
		.table,
		.inner-table {
			&-head .text {
				color: var(--neutral-70);
				font-weight: bold;
			}
		}
		// Inner table styles
		.inner-table {
			&-head {
				border-block: .1rem solid var(--neutral-20);
				display: block;
				background-color: var(--neutral-10);
			}
			&-body .inner-table-row:not(:last-child) {
				border-bottom: .1rem solid var(--neutral-20);
			}
			&-row {
				min-height: 4rem;
				align-items: center;
			}
			&-data {
				height: unset;
				padding-block: var(--space-2);
				flex-basis: var(--inner-table-data-width);
				&.date {
					flex-basis: var(--inner-date-width);
				}
				&.event-name {
					flex: 1;
				}
				&.time {
					flex-basis: var(--inner-time-width);
				}
				&.inner-points {
					flex-basis: var(--inner-points-width);
				}
			}
		}
		.event-name {
			min-height: unset;
			order: unset;
		}
		.counted .inner-points .text {
			gap: var(--space-2);
		}
	}
	.case-2,
	.case-4 {
		--inner-date-width: 14%;
		--inner-points-width: 10%;
		.table {
			&-body {
				.points-1,
				.points-2 {
					.text {
						font-weight: bold;
					}
				}
			}
			&-data {
				flex-basis: 6%;
				&.team-name {
					flex: 1;
					justify-content: flex-start;
				}
			}
		}
		.inner-table-data {
			flex-basis: 9%;
		}
		.events-1 {
			padding-left: 0;
		}
		.points-2 {
			padding-right: 0;
		}
	}
	.case-2 {
		--yob-width: 6.5%;
		--nation-width: 8.5%;
		--total-points-width: 7.5%;
		.table {
			&-body {
				.events-1,
				.points-1,
				.events-2,
				.points-2,
				.total-points {
					justify-content: center;
				}
			}
			&-data {
				&.events-1,
				&.points-1 {
					flex-basis: 9.25%;
				}
				&.events-2,
				&.points-2 {
					flex-basis: 7%;
				}
				&.last-name {
					flex: 0 0 11%;
				}
			}
		}
	}
	.case-4 {
		--yob-width: 6%;
		--nation-width: 8%;
		--total-points-width: 10%;
		.table {
			&-data {
				&.events-1,
				&.events-2,
				&.points-1,
				&.points-2 {
					flex-basis: 10%;
				}
			}
		}
	}
	.case-3 {
		--action-width: 5%;
		--yob-width: 7%;
		--nation-width: 10%;
		--events-width: 10%;
		--total-points-width: 11.5%;
		.table {
			&-body {
				.subclass,
				.total-events {
					.text {
						font-weight: 700;
					}
				}
			}
			&-data {
				flex-basis: 9.5%;
				&.subclass {
					flex-basis: 12%;
				}
			}
		}
	}	
}
@media screen and (min-width: $lg-min-width), print and (orientation: landscape) {
	.waf-standings {
		--pos-wrap-width: 7.9rem;
		--pos-width: 3.5rem;
		.text {
			font-size: 1.4rem;
		}
		.yob .text {
			font-size: 1.4rem;
		}
		.position {
			gap: var(--space-1);
		}
		.inner-table-data .text {
			font-size: 1.4rem;
		}
	}
}
@include mq(col-desktop) {
	.waf-standings {
		--row-gap: var(--space-4);
		--player-image-width: 4rem;
		--flag-width: 2.4rem;
		--action-width: 2.5%;
		--pos-wrap-width: 9.4rem;
		--pos-width: 4.6rem;
		--diff-width: 4.4rem;
		--yob-width: 11%;
		--nation-width: 10%;
		--events-width: 10.5%;
		--total-points-width: 15%;
		--inner-date-width: 9%;
		--inner-time-width: 12.5%;
		--inner-points-width: 15%;
		.waf-body {
			padding-bottom: var(--space-10);
		}
		.tab-container,
		.tabs {
			padding-inline: var(--space-10);
		}
		.table {
			&-filter {
				margin-bottom: var(--space-16);
				gap: var(--space-10);
			}
			&-head {
				.table-data::after {
					font-size: 2rem;
				}
				.ascending,
				.descending {
					&::after {
						font-size: 1.6rem;
					}
				}
			}
			&-body {
				.total-points .text {
					font-size: 1.6rem;
				}
			}
		}
		.position {
			gap: 0;
		}
		.position,
		.name,
		.last-name,
		.yob,
		.total-events,
		.events-1,
		.events-2,
		.points-1,
		.points-2,
		.total-points,
		.subclass,
		.nation {
			padding-left: var(--space-1);
		}
		.pos {
			font-size: 1.8rem;
		}
		.diff.text {
			font-size: 1.4rem;
		}
		.btn-accordion::before {
			font-size: 2rem;
		}
	}
	.case-2,
	.case-4 {
		--action-width: 3%;
		--total-points-width: 8.5%;
		--inner-date-width: 8%;
		--inner-time-width: 6.5%;
		--inner-points-width: 6.5%;
		.table-head .text {
			font-size: 1.4rem;
		}
		.table-body {
			.events-1,
			.points-1,
			.events-2,
			.points-2 {
				.text {
					font-size: 1.6rem;
				}
			}
		}
		.inner-table-data {
			flex-basis: 6.5%;
		}
		.inner-table-row {
			grid-template-columns: 8% 1fr 6.5% 6.5% 6.5% 6.5%;
		}
	}
	.case-2 {
		--yob-width: 5%;
		--nation-width: 7.5%;
		--total-points-width: 7.5%;
		.table-data {
			&.last-name {
				flex: 1;
			}
			&.events-1 {
				flex-basis: 11%;
			}
			&.events-2 {
				flex-basis: 7.5%;
			}
		}
	}
	.case-3 {
		--action-width: 4.5%;
		--yob-width: 5.5%;
		--nation-width: 8%;
		--events-width: 11%;
		.table-body {
			.subclass,
			.total-events {
				.text {
					font-size: 1.6rem;
				}
			}
		}
	}
	.case-4 {
		--yob-width: 5.5%;
		--total-points-width: 12%;
		.table {
			&-body {
				.points-1,
				.points-2 {
					.text {
						font-size: 1.4rem;
						font-weight: 400;
					}
				}
			}
			&-data {
				&.events-1,
				&.events-2,
				&.points-1,
				&.points-2 {
					flex-basis: 12%;
				}
			}
		}
		.total-points {
			justify-content: flex-start;
		}
	}
}
@media print {
	.menu-wrap, .footer-wrapper {
		display: none;
	}
	.waf-standings {
		background-color: var(--neutral-0);
		.waf-head {
			height: auto;
			min-height: unset;
			padding-block: 3rem;
			background: unset;
			&::before {
				content: unset;
			}
			.title {
				color: var(--neutral-100);
			}
		}
		.tab {
			&s {
				border-bottom: 0;
			}
			&-item.active .tab-name::before {
				background-color: var(--neutral-100);
			}
		}
		.desc {
			color: var(--neutral-100);
		}
		.head-tag, .head-tab {
			display: none;
		}
		.waf-body {
			padding-top: 0;
			margin-top: 0;
			border: 0;
			border-radius: unset;
			background-color: unset;
		}
		.table {
			border-radius: 0;
			&-head {
				border-radius: 0;
				.table-data::after {
					content: unset;
				}
			}
			&-body .last-name .text {
				color: var(--neutral-100);
			}
			&-row-wrap:last-child {
				border-radius: 0;
			}
			&-row {
				page-break-inside: avoid;
			}
			&-data {
				height: 5rem;
			}
		}
		.inner-table-row {
			page-break-inside: avoid;
		}
		.head-tag, .head-tab, .table-filter, .pagination-wrap {
			display: none !important;
		}
		.table-content-wrap {
			.text, li {
				page-break-inside: avoid;
			}
		}
		.action {
			display: none;
		}
		.ascent, .descent {
			.diff::before {
				content: unset;
			}
		}
		.fname {
			color: var(--neutral-100);
		}
		.event-name .text-link {
			color: var(--neutral-100);
		}
		.counted .text {
			font-weight: 600;
		}
	}
	#onetrust-consent-sdk, .snackbar-wrap, .uwy {
		display: none !important;
	}
}
@use '../config/' as *;
.filter {
    &-section {
        @extend %px-4;
        .btn {
            &-more {
                border: 0.1rem solid var(--primary-50);
                border-radius: 3.2rem;
                @extend %p-3-5;
                @extend %text-mb;
                @extend %bg-neutral-0;
                @extend %flex-n-c;
                @extend %gap-2;
                &[aria-expanded=true] {
                    ~ .filter-section-wrapper {
                        right: 0;
                    }
                }
                .btn-text {
                    @extend %primary-50;
                    @extend %flex-n-c;
                    @extend %gap-2;
                    &:after {
                        content: "";
                        @include icon(filter, 20);
                    }
                }
            }
            &-cancel {
                @extend %bg-transparent;
                .btn-text {
                    @extend %font-0;
                    &:after {
                        content: '';
                        @include icon(close, 26);
                        @extend %neutral-80;
                    }
                }
            }
            &-close {
                right: 0;
                position: relative;
            }
        }
        &-wrapper {
            border-radius: 1.6rem 1.6rem 0 0;
            height: calc(var(--window-inner-height) - 8rem);
            @include position(calc(var(--header-height) + var(--space-3)), 100%, null, null, fixed);
            @extend %w-100;
            @extend %bg-neutral-0;
            @extend %zindex-filter;
        }
        .head {
            height: 7.6rem;
            border-bottom: 0.1rem solid var(--neutral-20);
            @extend %flex-sb-c;
            @extend %p-6;
            .label {
                @extend %title-50;
                @extend %neutral-100;
                @extend %m-0;
            }
        }
        .body {
            padding: var(--space-6) var(--space-4) 0;
            height: calc(100% - 15.6rem);
            @extend %y-auto;
        }
        .footer,
        .select-box-footer {
            height: 8rem;
            @extend %flex-c-c;
            @extend %p-4;
            @extend %w-100;
            @extend %gap-1;
            .btn {
                @extend %w-50;
            }
        }
        .select-box-footer {
            @include border(1, neutral-20, 10, top);
        }
        .btn-applyfilter {
            @extend %btn-fill;
        }
        .btn-resetfilter {
            @extend %bg-transparent;
            @extend %btn-default;
        }
        .sub-title {
            @extend %d-none;
        }
        .selected-title {
            border-bottom: 0.1rem solid var(--neutral-20);
            @extend %body-l;
            @extend %neutral-100;
            @extend %flex-n-c;
            @extend %gap-2;
            @extend %p-4;
            @extend %w-100;
            &[aria-expanded=true] {
                &:after {
                    @include icon(chevron-up, 24);
                }
            }
            &::after {
                content: '';
                @include icon(chevron-down, 24);
                @extend %ml-auto;
            }
            &.active {
                border-bottom: 0.1rem solid var(--primary-50);
            }
        }
        .site-search-form {
            @extend %px-4;
        }
        .dropdown {
            &-list {
                height: calc(100% - 8rem);
                @extend %d-none;
            }
            &-list-head {
                height: 7rem;
                border-radius: 1.2rem 1.2rem 0 0;
                order: -1;
                border-bottom: 0.1rem solid var(--neutral-20);
                @extend %flex-sb-c;
                @extend %p-6;
                .btn-back {
                    @extend %flex-n-c;
                    @extend %gap-4;
                    &:before {
                        content: '';
                        @include icon(arrow-left, 24);
                    }
                    .btn-text {
                        @extend %neutral-100;
                        @extend %title-50;
                    }
                }
            }
            &-list-body {
                height: calc(100% - 8rem);
                @extend %px-4;
                @extend %y-auto;
                @include custom-scroll();
                .selected-title {
                    &[aria-expanded=true] {
                        + .select-box-wrap {
                            @extend %d-block;
                        }
                    }
                }
            }
            &-item {
                &.radio-select {
                    .dropdown-list {
                        .dropdown-list-body {
                            > .select-box-wrap {
                                display: block;
                                .label-wrapper {
                                    label {
                                        position: unset;
                                        &::before {
                                            content: unset;
                                        }
                                    }
                                    input[type=radio] {
                                        @extend %w-100;
                                        &:checked {
                                            + label {
                                                &::before {
                                                    content: unset;
                                                }
                                                &::after {
                                                    right: 0;
                                                    left: auto;
                                                    border-color: var(--positive-50);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.active {
                    .dropdown-list {
                        border-radius: var(--space-4);
                        @include position-combo(tl);
                        @extend %flex-column;
                        @extend %w-100;
                        @extend %bg-neutral-0;
                        @extend %h-100;
                        .dropdown-list-body {
                            .selected-title {
                                &.active {
                                    + .select-box-wrap {
                                        @extend %d-block;
                                    }
                                }
                                + .select-box-wrap {
                                    @extend %d-none;
                                }
                            }
                        }
                        .select-box-footer {
                            display: flex;
                        }
                    }
                }
            }
        }
        .select {
            &-box-footer {
                @extend %d-none;
            }
            .select-list {
                gap: 0;
            }
        }
        .list-item {
            &:not(:last-child) {
                border-bottom: 0.1rem solid var(--neutral-20);
            }
            @extend %p-2;
        }
        .label-wrapper {
            @extend %flex-n-c;
            @extend %gap-2;
            @extend %relative;
            input {
                opacity: 0;
                cursor: pointer;
                height: 2rem;
                position: absolute;
                aspect-ratio: 1/1;
                @include position-combo(y-center, null, 0);
                @extend %w-100;
                @extend %p-0;
                @extend %mb-0;
                @extend %zindex-input;
                &:checked {
                    & + label {
                        &:after {
                            content: '';
                            width: 0.6rem;
                            height: 1.4rem;
                            border: solid var(--neutral-0);
                            border-width: 0 .2rem .2rem 0;
                            transform: rotate(35deg);
                            @include position(0.5rem, null, null, 1.1rem);
                            @extend %d-block;
                        }
                        &::before {
                            border-color: var(--neutral-0);
                            @extend %bg-primary-50;
                        }
                    }
                }
            }
            label {
                cursor: pointer;
                @extend %relative;
                @extend %text-m;
                @extend %flex-n-c;
                &:before {
                    content: '';
                    border: 0.2rem solid var(--neutral-20);
                    display: inline-block;
                    vertical-align: middle;
                    cursor: pointer;
                    width: 2rem;
                    height: 2rem;
                    aspect-ratio: 1/1;
                    border-radius: 0.5rem;
                    @extend %p-3;
                    @extend %mr-2;
                    @extend %relative;
                    @extend %bg-transparent;
                }
            }
        }
        .selected-option{
            width: 2.4rem;
            aspect-ratio: 1/1;
            @extend %flex-c-c;
            @extend %circle-radius;
            @extend %bg-primary-50;
            @extend %neutral-0;
            @extend %font-14-pb;
        }
    }
}
@include mq(col-tablet) {
    .filter {
        &-section {
            &-wrapper {
                width: 43rem;
                top: var(--space-6);
                border-radius: 1.6rem;
            }
            .btn {
                &-more {
                    &[aria-expanded=true] {
                        ~ .filter-section-wrapper {
                            right: var(--space-6);
                        }
                    }
                }
            }
            .body {
                @include custom-scroll();
            }
        }
    }
    .upfront-filter {
        .filter-section {
            padding: 0;
            &-wrapper {
                position: relative;
                background-color: transparent;
                width: 100%;
                flex-wrap: wrap;
                right: 0;
                top: unset;
                overflow: unset;
                padding: 0;
                height: auto;
                z-index: 4;
                @include flex-config(flex);
            }
            .btn-more,
            .head,
            .dropdown-list-head,
            .badge {
                display: none;
            }
            .body {
                gap: var(--space-6);
                overflow: unset;
                height: auto;
                flex-wrap: wrap;
                @include flex-config(flex);
            }
            .selected-title {
                padding-bottom: var(--space-3);
                padding-top: 0;
                .btn-text {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                    overflow: hidden;
                }
            }
            .dropdown {
                &-item {
                    position: relative;
                    min-width: 19rem;
                    // width: 100%;
                    width: 19rem;
                    &.active {
                        .dropdown-list {
                            position: absolute;
                            top: unset;
                            right: 0;
                            width: 36rem;
                            height: 33rem;
                            box-shadow: 0 .2rem 1.5rem 0 var(--neutral-20);
                            z-index: var(--z-filter-dropdown);
                            @include bg(neutral-0);
                        }
                    }
                    &.selected-active {
                        .selected-title {
                            border-color: var(--primary-50);
                        }
                    }
                }
                &-list-body {
                    height: calc(100% - 8rem);
                    flex-grow: 1;
                    overflow-y: auto;
                    @include custom-scroll();
                }
                &-list {
                    .selected-title {
                        padding: var(--space-4);
                    }
                }
            }
            .dropdown-item.radio-select.active {
                .dropdown-list {
                    height: 25rem;
                }
                .select-box-footer {
                    display: none;
                }
            }
            .sub-title {
                display: block;
                font-size: 1.2rem;
                padding-left: var(--space-4);
            }
            .select-box-footer {
                display: flex;
                align-items: center;
                height: 8rem;
                gap: var(--space-2);
                padding: var(--space-4);
                border-radius: 0 0 1.6rem 1.6rem;
                box-shadow: 0 .2rem 1.5rem 0 var(--neutral-20);
                @include bg(neutral-0);
                .btn {
                    width: 50%;
                    height: 4.8rem;
                }
            }
            .footer {
                width: 17rem;
                margin-top: var(--space-2);
                .btn-applyfilter {
                    display: none;
                }
                .btn-resetfilter {
                    @include bg(primary-50);
                    @include color(neutral-0);
                    &:disabled {
                        color: var(--neutral-100);
                        background-color: var(--informative-0);
                    }
                }
                .btn {
                    width: 100%;
                }
            }
        }
        &.development-calendar-page {
            .filter-section {
                .head {
                    display: block;
                    width: 100%;
                    border-bottom: unset;
                    .btn-cancel {
                        display: none;
                    }
                }
            }
        }
    }
}